import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import router from "@/router";
import navbar_shower from "./navbar_shower";

const vuex = createStore({
  plugins: [createPersistedState()],
  state: {
    path: null,
    token: null,
    user: null,
    redirect: null,
    saveResetPassWordPhone: null,
    saveResetPassWordToken: null,
    souscriptionPosition: 1,
    productType: 'AUTO',
    souscritionDetails: null,
    productData: {
      vehicleFound: null,
    },
    newDevis: null,
    oldEstimate: null,
  },

  mutations: {
    save_path (state, position) {
      state.path = position;
    },
    save_token(state, position) {
      state.token = position;
    },
    save_user(state, position) {
      state.user = position;
    },
    save_redirect(state, position) {
      state.redirect = position;
    },
    save_Reset_PassWord_Token(state, position) {
      state.saveResetPassWordToken = position;
    },
    save_Reset_PassWord_Phone(state, position) {
      state.saveResetPassWordPhone = position;
    },
    save_position (state, position) {
    state.souscriptionPosition = position
    },
    save_product (state, product) {
      state.productType = product
    },
    save_souscription (state, data) {
    state.souscritionDetails = data
    },
    save_product_data (state, data) {
    state.productData = data
    },
    save_devis (state, devis) {
      state.newDevis = devis
    },
    save_old_estimate (state, data) {
      state.oldEstimate = data
    },
    logout(state, val) {
      state.token = val;
      state.user = val;
      state.path = null;
      state.redirect = null;
      state.newDevis = null;
      state.oldEstimate = null;
      router.push('/login')
    },
  },

  actions: {
    savePath({ commit }, c) {
      commit("save_path", c);
    },
    saveToken({ commit }, c) {
      commit("save_token", c);
    },
    saveUser({ commit }, c) {
      commit("save_user", c);
    },
    saveRedirect({ commit }, c) {
      commit("save_redirect", c);
    },
    saveResetPassWordToken({ commit }, c) {
      commit("save_Reset_PassWord_Token", c);
    },
    saveResetPassWordPhone({ commit }, c) {
      commit("save_Reset_PassWord_Phone", c);
    },
    position ({ commit }, c) {
      commit('save_position', c)
    },
    product ({ commit }, c) {
      commit('save_product', c)
    },
    saveSouscription ({ commit }, c) {
    commit('save_souscription', c)
    },
    saveProductData ({ commit }, c) {
    commit('save_product_data', c)
    },
    saveFirstDevis ({ commit }, c) {
      commit('save_devis', c)
    },
    saveOldEstimate ({ commit }, c) {
      commit('save_old_estimate', c)
    },
    logout({ commit }, c) {
      commit("logout", c);
    },
  },

  getters: {
    selectedPath: state => state.path,
    token: (state) => state.token,
    user: (state) => state.user,
    redirectData: (state) => state.redirect,
    saveResetPassWordToken: (state) => state.saveResetPassWordToken,
    saveResetPassWordPhone: (state) => state.saveResetPassWordPhone, 
    trackPosition: state => state.souscriptionPosition,
    trackProduct: state => state.productType,
    souscriptionDetail: state => state.souscritionDetails,
    actualProductData: state => state.productData,
    trackFirstDevis: state => state.newDevis,
    isOldEstimate: state => state.oldEstimate,
  },

  modules: {
    navbar_shower,
  },
});

export default vuex;
