export default {
  "lorem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum dolor sit amet consectetur. Aliquam eu elementum id pharetra consectetur integer. Faucibus eget risus nec nunc. Odio lorem pharetra adipiscing ipsum suspendisse dui dignissim et. Pellentesque adipiscing sit nec tempor pellentesque quis amet."])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mon-assurance"])},
  "Aide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aide"])},
  "verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifier une assurance"])},
  "Contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
  "clientSpace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espace client"])},
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue"])},
  "enterId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer votre numéro de téléphone pour continuer."])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
  "loginButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter"])},
  "forgetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié ?"])},
  "forgetPasswordTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié"])},
  "forgetEnterEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié ? Entrez votre numéro de téléphone ci-dessous."])},
  "getMyAccountButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récupérer mon compte"])},
  "codeSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Nous allons envoyer un code de vérification à 4 chiffres par SMS sur ce numéro."])},
  "loginPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page de connexion"])},
  "recoveryLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien de récupération"])},
  "smsNotReceived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n’avez pas reçu le code ?"])},
  "incorrect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code incorrect"])},
  "tryAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réessayer"])},
  "verification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérification"])},
  "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])},
  "putCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir le code envoyé par SMS sur le"])},
  "sendRecoveryLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous vous avons envoyé un lien de récupération par message. \nConsultez votre boîte de messagerie et suivez les instructions."])},
  "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau mot de passe"])},
  "definePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez définir votre nouveau mot de passe"])},
  "confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])},
  "recoverPasswordButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser mon mot de passe"])},
  "passwordRecovered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe réinitialisé"])},
  "passwordIsRecover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre mot de passe a été réinitialisé avec succès. \nConnectez-vous pour accéder à votre espace de travail."])},
  "clause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clause de non-responsabilité"])},
  "choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faites votre choix en tenant compte de vos besoins et des garanties proposées. Le prix ne doit pas être le seul critère de choix de votre police d’assurance. N’hésitez pas à nous contacter pour des conseils sur mesure."])},
  "accueil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accueil"])},
  "needCall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous souhaitez être rappelé ?"])},
  "putDispo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez nous indiquer votre disponibilité, un téléconseiller vous rappelera."])},
  "saveAppointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer mon rendez-vous"])},
  "requiredRdv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pré-requis pour mon rendez-vous !"])},
  "callAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appelez-nous au"])},
  "subjectConversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sujet de discussion"])},
  "objectConversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objet de la discusion"])},
  "hiUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salut"])},
  "were": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On est le"])},
  "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveautés"])},
  "assuranceExp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["assurance bientôt expirée"])},
  "myDevis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes devis"])},
  "mySinisters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes sinistres"])},
  "declareSinisters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déclarer un sinistre"])},
  "myEcheances": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes échéances"])},
  "doEstimation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faire une estimation"])},
  "myContracts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes contrats"])},
  "prorogation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prorogation"])},
  "canSubscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez désormais souscrire"])},
  "dontHesitate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N’hésitez pas à prendre rendez-vous ou nous contacter au besoin"])},
  "callUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appelez-nous au"])},
  "assurContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre contrat d’assurance automobile"])},
  "expIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["expire dans"])},
  "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jours"])},
  "renouveler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renouveler"])},
  "assurAuto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assurance automobile"])},
  "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actif"])},
  "exp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiré"])},
  "matricule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immatriculation"])},
  "destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination"])},
  "proprietaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propriétaire"])},
  "contrat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrat"])},
  "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée"])},
  "myVehicle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon véhicule "])},
  "seeAllInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir toutes les informations"])},
  "couvAndGarant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Couverture et garanties"])},
  "marque": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marque"])},
  "modele": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèle"])},
  "circDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date mise circulation"])},
  "motPuissance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puissance moteur"])},
  "chassis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chassis"])},
  "placeNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nbr. Place"])},
  "couverture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Couverture"])},
  "takeEffect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prise d’effet"])},
  "expiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiration"])},
  "assureur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assureur"])},
  "manageContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérer mon contrat"])},
  "intitule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intitulé"])},
  "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
  "glasse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bris de glace"])},
  "vol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vol"])},
  "vandalisme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vandalisme"])},
  "dammages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dommages"])},
  "advanceRecours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avance sur recours"])},
  "allRisk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOUS RISQUES"])},
  "assurPrime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primes d’assurance"])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["par an"])},
  "haveHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtenir de l’aide ?"])},
  "prime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prime"])},
  "assurAutoDevis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devis d’assurance automobile"])},
  "assurMotoDevis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devis d’assurance moto"])},
  "assurVoyageDevis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devis d’assurance voyage"])},
  "assurMrhDevis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devis d’assurance habitation"])},
  "offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["offres"])},
  "forMyvehicle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pour votre véhicule"])},
  "forMyvoyage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pour votre voyage"])},
  "forMymoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pour votre moto"])},
  "forMyhabitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pour votre habitation"])},
  "betterOffers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les meilleures offres"])},
  "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrer"])},
  "assurDevis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devis d’assurance"])},
  "automobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automobile"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["trouvées"])},
  "seeOffers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir les offres"])},
  "trySimulation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reprendre la simulation"])},
  "vehicleInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations du véhicule"])},
  "docRecap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récap. du dossier"])},
  "docAndAttest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents et attestations"])},
  "mdpMustHave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe doit comporter au moins une lettre majuscule, un chiffre, des lettres minuscules et un signe."])},
  "notSame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe n'est pas conforme."])},
  "putNewMpd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez remplir tous les champs."])},
  "subscribeOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souscrire en ligne"])},
  "moreDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de détails"])},
  "waiting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente"])},
  "treating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traitement en cours"])},
  "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceptée"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clos"])},
  "sinister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinistre"])},
  "newMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau message :"])},
  "completedDoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compléter mon dossier"])},
  "docEvolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evolution du dossier"])},
  "docDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails du dossier"])},
  "declaration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déclaration"])},
  "docToComplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document à compléter"])},
  "sinisterStatut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut du sinistre :"])},
  "editCalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edition du calendrier des travaux"])},
  "survDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de survenance :"])},
  "circonstances": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Circonstances :"])},
  "degats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dégâts :"])},
  "verbalProc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procès verbal :"])},
  "addDoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter le document"])},
  "modifCalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modification du calendrier"])},
  "docManquant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents manquant pour planification"])},
  "confPrise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation de la prise en charge"])},
  "confDeclaration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation de la déclaration"])},
  "myTechVisit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes visites techniques"])},
  "visitDone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visite effectuée"])},
  "effectue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effectuée"])},
  "visitAtEcheance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre visite technique est arrivée à échéance"])},
  "generateAttestation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Générer une attestation numérique"])},
  "selectAssur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez l’assurance concernée"])},
  "generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Générer"])},
  "docType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de document :"])},
  "myAttestation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes attestations"])},
  "documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents"])},
  "releves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes relevé de comptes assurances"])},
  "particularCondition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditions particulières"])},
  "generalCondition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditions générales"])},
  "pleaseAddDoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez ajouter le(s) document(s) à votre dossier"])},
  "checking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérification en cours..."])},
  "enterPasswd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer votre mot de passe pour continuer"])},
  "enterPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer votre numéro de téléphone pour continuer"])},
  "wrongId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiants incorrects"])},
  "errorOccured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s'est produite"])},
  "confirmPasswd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veillez confirmer le nouveau mot de passe"])},
  "enterNewPasswd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veillez remplir le nouveau mot de passe"])},
  "enterConfCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veillez entrer votre code de confirmation pour continuer"])},
  "accountNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de compte existant avec ce numéro"])},
  "createPasswordButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer mon mot de passe"])},
  "attestation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J’atteste également être conscient qu’en cas de fausses déclarations, des dispositions légales seront prises conformément aux articles du code de la CIMA ci-contre :"])},
  "firstArticle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Article Nº 18 : Fausse déclaration intentionnelle : sanctions"])},
  "firstArticleParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indépendamment des causes ordinaires de nullité, et sous réserve des dispositions de l’article 80, le contrat d’assurance est nul en cas de réticence ou de fausse déclaration intentionnelle de la part de l’assuré, quand cette réticence ou cette fausse déclaration change l’objet du risque ou en diminue l’opinion pour l’assureur, alors même que le risque omis ou dénaturé par l’assuré a été sans influence sur le sinistre. Les primes payées demeurent alors acquises à l’assureur, qui a droit au paiement de toutes les primes échues à titre de dommages et intérêts. Les dispositions du second alinéa du présent article ne sont pas applicables aux assurances sur la vie."])},
  "secondArticle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Article Nº 19 : Fausse déclaration non intentionnelle"])},
  "secondArticleParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’omission ou la déclaration inexacte de la part de l’assuré dont la mauvaise foi n’est pas établie n’entraîne pas la nullité de l’assurance. Si elle est constatée avant tout sinistre, l’assureur a le droit de maintenir le contrat, moyennant une augmentation de prime acceptée par l’assuré, soit de résilier le contrat dix jours après notification adressée à l’assuré par lettre recommandée ou contresignée, en restituant la portion de la prime payée pour le temps où l’assurance ne court plus. Dans le cas où la constatation n’a lieu qu’après un sinistre, l’indemnité est réduite en proportion du taux des primes payées par rapport au taux des primes qui auraient été dues, si les risques avaient été complètement et exactement déclarés."])},
  "declarationHonneur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déclaration sur l’honneur"])},
  "attesteQue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["atteste que les informations fournies ci-haut sont correctes et m’engagent personnellement."])},
  "soussigne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je soussigné(e)"])},
  "consultCIMA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulter le code CIMA :"])},
  "assurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assurance"])},
  "automobileLow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["automobile"])},
  "travelDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée de voyage"])},
  "batimentType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de batiment"])},
  "batimentAdress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse du batiment"])},
  "monthlyRent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loyer mensuel"])},
  "batimentValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur du batiment"])},
  "contentValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur du contenu"])},
  "categorie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie"])},
  "carburation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carburation"])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])}
}