import { createRouter, createWebHistory, } from "vue-router";
import pathLoader from "../plugins/loader"
import store from "../store/store";

const routes = [
  {
      path: "/login",
      name: "login",
      component: pathLoader("login"),
  },
  {
    path: "/automobile",
    name: "homeParcour",
    component: pathLoader(null, "homeParcour"),
    redirect: "/automobile",
    children: [
      {
        path: "/automobile",
        name: "automobile",
        component: pathLoader("automobile"),
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: "/moto",
        name: "moto",
        component: pathLoader("moto"),
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: "/voyage",
        name: "voyage",
        component: pathLoader("voyage"),
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: "/habitation",
        name: "mrh",
        component: pathLoader("mrh"),
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: "/verification",
        name: "verification",
        component: pathLoader("verification"), 
        meta: {
          requiresAuth: false,
        },
      },
    ]
  },
  {
    path: "/",
    name: "homePrivate",
    component: pathLoader(null, "homePrivate"),
    redirect: "/login",
    children: [
      {
        path: "/accueil",
        name: "accueil",
        component: pathLoader("accueil"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/contrats",
        name: "contrats",
        component: pathLoader("contrats"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/devis",
        name: "devis",
        component: pathLoader("devis"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/sinistres",
        name: "sinistres",
        component: pathLoader("sinistres"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/echeances",
        name: "echeances",
        component: pathLoader("echeances"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/documents",
        name: "documents",
        component: pathLoader("documents"),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    let code = window.location.search

    if (store.getters.token) {
      const user = store.getters.user
      if (user.tokenStarter) {
        const dayDif = new Date().getTime() - new Date(user.tokenStarter).getTime()
        const totalHour = (dayDif / 1000)/3600

        if (totalHour < 24) {
          next();
          return;
        } else {
          store.dispatch('logout')
          router.push('/login')
        }

      } else {
        store.dispatch('logout')
        router.push('/login')
      }
    } else  {
      if (code) {
        store.dispatch('saveRedirect', window.location.search)
      }

      next("/login");
    }

  } else {
    if (to.matched.some((record) => record.meta.token)) {
      const query = window.location.search
      if (query) {
        if (query.indexOf('id') > 0 && query.indexOf('token') > 0) {
          next();
        }
      }

    } else {
      next();
    }

  }
});

export default router;
